import React, { Component } from 'react'
import Footer from '../../common/Footer.js'
import ClientSideNav from '../../common/ClientSideNav'
import TopNav from '../../common/TopNav.js'
import * as moment from 'moment'
import debounce from 'lodash.debounce';
import download from "downloadjs";
import { Table } from "react-bootstrap";
import { connect } from 'react-redux'
import requireAuth from '../../hoc/requireAuth'
import authorizeClient from '../../hoc/authorizeClient'
import NotAccessablePage from '../NotAccessablePage.js'
import { getNewSortOrder } from '../../helpers.js';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import Loader from "react-loader-spinner";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { castToServerDateFormat, castToDefaultDateFormat } from '../../settings/index'
import { changeClientDateFilter } from '../../store/actions/filterActions'
import InfrigementStatusDropdownComponent from './../../components/InfrigementStatusDropdownComponent'
import { withAlert } from "react-alert";

class AppStoreDashboard extends Component {
  constructor(props) {
    super(props);
    this.getAppStoreData = debounce(this.getAppStoreData.bind(this), 1000);
    this.changeSorting = this.changeSorting.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      sorting: {
        sortBy: '',
        sortIconClass: 'sort-icon-nutral',
        sortOrder: ''
      },
      authorised: true,
      domains: [],
      visibleRecords: "",
      dropdownCount: false,
      pagination: {
        page: 1,
        nextPage: 1,
        prevPage: null,
        hasNextPage: true,
        hasPrevPage: false,
        pagingCounter: 1,
        totalDocs: null,
        totalPages: null
      },
      perPage: 10,
      hasMoreDomainData: true,
      isLoading: false,
      locale: { format: 'DD/MM/YYYY' }
    }

    window.onscroll = debounce(async () => {
      const {
        getAppStoreData,
        state: {
          hasMoreDomainData,
          dropdownCount
        }
      } = this;

      if (!hasMoreDomainData) {
        return;
      }

      // Calculate the scroll position
      const scrollPosition =
        window.innerHeight + document.documentElement.scrollTop;

      // Check if scrolled to the bottom
      if (scrollPosition === document.documentElement.offsetHeight) {
        if (dropdownCount) {
          // Set dropdownCount to false to fetch all data
          this.setState({ dropdownCount: false });
        } else {
          // Paginate the data
          await getAppStoreData(false, true);
        }
      }
    }, 100);

  }

  changeSorting(sortBy) {

    this.setState((prevState) => {

      const { sortOrder, classPostFix } = getNewSortOrder(prevState)

      return {
        sorting: {
          sortBy,
          sortIconClass: `sort-icon-${classPostFix}`,
          sortOrder
        }
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sorting.sortBy !== this.state.sorting.sortBy
      || prevState.sorting.sortOrder !== this.state.sorting.sortOrder) {
      this.getAppStoreData(true)
    }

    if (prevProps.selectedBrand !== this.props.selectedBrand) {
      this.getAppStoreData(true)
    }

    if (prevProps.clientAppStoreFilter !== this.props.clientAppStoreFilter) {
      this.getAppStoreData(true)
    }

    if (prevProps.clientDateFilter !== this.props.clientDateFilter) {
      this.getAppStoreData(true)
    }

    if (prevProps.location?.state?.notification !== this.props.location?.state?.notification) {
      this.getAppStoreData(true)
    }

    if (prevState.domains.length !== this.state.domains.length) {
      this.setState({ visibleRecords: this.state.domains.length }, () => { });
    }
  }

  async downloadExcel() {
    const options = {
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Content-disposition': 'attachment; filename=app-store.xlsx"'
      }
    }

    let reqData = {
      startDate: castToServerDateFormat(this.props.clientDateFilter.startDate),
      endDate: castToServerDateFormat(this.props.clientDateFilter.endDate),
      module: 'app-store',
      ...this.props?.clientAppStoreFilter
    }

    if (this.props.selectedBrand?.id) {
      reqData.brand = this.props.selectedBrand?.id
    }

    if (this.props.selectedCompany?.id) {
      reqData.clientCompanyId = this.props.selectedCompany?.id;
    }

    await window.axios.get('/app-store/download-xls', {
      params: reqData,
      responseType: 'blob',
      timeout: 30000,
    }, options).then(res => {
      download(res.data, 'app-store.xls');
    });
  }

  async getAppStoreData(reArrangeRecords = false, isPerPage = false) {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };

    let reqData = {
      dropdownCount: this.state.dropdownCount,
      sortBy: this.state.sorting.sortBy,
      sortOrder: this.state.sorting.sortOrder,
      // page: this.state.pagination.nextPage,
      page: this.state.pagination.page,
      perPage: isPerPage ? this.state.visibleRecords + 20 : this.state.perPage,
      startDate: castToServerDateFormat(this.props.clientDateFilter.startDate),
      endDate: castToServerDateFormat(this.props.clientDateFilter.endDate),
      ...this.props?.clientAppStoreFilter,
    };

    if (reArrangeRecords) {
      reqData.page = 1
    }

    if (this.props.selectedBrand?.id) {
      reqData.brand = this.props.selectedBrand?.id
    }

    if (this.props.location?.state?.notification) {
      reqData.notification = this.props.location?.state?.notification;
    }

    if (this.props.selectedCompany?.id) {
      reqData.clientCompanyId = this.props.selectedCompany?.id;
    }

    reqData.module = 'app-store'
    this.setState({ isLoading: true })

    await window.axios.get('/app-store', { params: reqData }, options).then(res => {
      let domainData = res.data.docs;

      // if (reqData.dropdownCount) {
      domainData = domainData;
      // } else {
      //   domainData = [...this.state.domains].concat(domainData);
      // }

      if (reArrangeRecords) {
        domainData = res.data.docs;
      }

      this.setState((prevState) => ({
        pagination: {
          ...prevState.pagination, // Spread the existing pagination state
          // page: res.data.page, // Commenting this out will retain the existing value
          nextPage: res.data.nextPage,
          prevPage: res.data.prevPage,
          hasNextPage: res.data.hasNextPage,
          hasPrevPage: res.data.hasPrevPage,
          pagingCounter: res.data.pagingCounter,
          totalDocs: res.data.totalDocs,
          totalPages: res.data.totalPages,
        },
        domains: domainData, hasMoreDomainData: res.data.hasNextPage, isLoading: false
      }));
    }).catch(() => { }).then(() => {
      this.setState({ isLoading: false })
    });
  }

  getDateFilters() {
    return {
      startDate: moment(this.props.clientDateFilter.startDate),
      endDate: moment(this.props.clientDateFilter.endDate),
      locale: this.state.locale
    }
  }

  handleCallback = async (event, picker) => {

    this.props.dispatch(changeClientDateFilter({
      startDate: picker.startDate,
      endDate: picker.endDate
    }))

  }

  ////    LDOTR-12 Start   ////
  //This function handle the dropdown input (10,20,50) record by the user.
  handleSelect(countValue) {
    this.setState((prevState) => ({
      dropdownCount: countValue,
      pagination: {
        ...prevState.pagination,
        page: 1,
      },
      // perPage: countValue,
    }));
    this.getAppStoreData();
  }
  ////    LDOTR-12 End   ////

  renderLoader() {
    if (this.state.isLoading) {
      return <div className="text-center">
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={10}
          width={200}
          timeout={5000} //3 secs
        />
      </div>
    }
  }

  componentDidMount() {
    this.getAppStoreData(null, false);
    this.setState({ visibleRecords: this.state.domains.length }, () => { });
  }

  updateClientCommentInArray(value, key) {
    let records = this.state.domains;

    records[key]["clientComment"] = value;

    this.setState(() => ({
      domains: records,
    }));
  }

  async updateClientCommentToDatabase(key) {
    let clientComment = this.state.domains[key]["clientComment"];
    let infrigementId = this.state.domains[key]["id"];

    if (!clientComment) {
      return;
    }

    const options = {
      headers: { "Content-Type": "application/json" },
    };

    await window.axios
      .post(
        `/app-store/${infrigementId}/update-comment`,
        {
          clientComment,
        },
        options
      )
      .then((_) => {
        this.props.alert.success("Comment updated successfully.");
      })
      .catch(function (error) {
        this.props.alert.error(error.response.data.message);
      });
  }

  printPaginationInfo() {
    let totalDocs = this.state.pagination.totalDocs ? this.state.pagination.totalDocs : 0
    let visibleRecords = 0;

    if (totalDocs) {
      visibleRecords = (totalDocs > (this.state.perPage * this.state.pagination.page)) ? this.state.perPage * this.state.pagination.page : totalDocs
    }

    ////    LDOTR-12 Start   ////
    return (
      <>
        <div className="d-flex align-items-center">
          <span className="text-uppercase page-subtitle">
            Showing <strong>{this.state.visibleRecords}</strong> records of{" "}
            <strong>{totalDocs}</strong>
          </span>
          <div className="dropdown ml-3">
            <button
              className="btn btn-secondary dropdown-toggle custom-dropdown-button"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                backgroundColor: "white",
                width: "130px",
                color: "black", // Add the color property for text visibility
                height: "20px", // Adjust the height as per your requirements
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "1",
              }}
            >
              {/* {this.state.dropdownRecordsPlug
                ? this.state.dropdownRecords.length
                : visibleRecords} */}
              {this.state.visibleRecords} Records
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(10)}
              >
                Show 10 records
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(20)}
              >
                Show 20 records
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(50)}
              >
                Show 50 records
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(100)}
              >
                Show 100 records
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(20000)}
              >
                Show All records
              </a>
            </div>
          </div>
        </div>
      </>
    );
    ////    LDOTR-12 End   ////
  }

  renderHtml() {
    if (!this.props.selectedBrand?.platforms?.appStore) {
      return <NotAccessablePage />
    }

    return <div className="row">

      <ClientSideNav />

      <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">

        <TopNav />

        <div className="main-content-container container-fluid px-4">
          <div className="page-header row no-gutters py-4">
            <div className="col-12">
              <div className="row">
                <div className="col-3 text-sm-left mb-0">
                  <span className="text-uppercase page-subtitle">App Store Dashboard
                  </span>
                  {this.printPaginationInfo()}
                </div>
                <div className="col-2 text-sm-right mb-0">
                  {this.renderLoader()}
                </div>
                <div className="col-6 text-sm-right mb-0">
                  <span className="uppercase-text"><strong>Reporting Date: </strong></span>
                  <DateRangePicker
                    onApply={this.handleCallback}
                    initialSettings={this.getDateFilters()}
                    alwaysShowCalendars={true}
                  >
                    <input className="from-control" />
                  </DateRangePicker>
                </div>
                <div className="col-1 text-sm-right mb-0">
                  <a title="Download as xls" href="#" className="display-6" onClick={() => this.downloadExcel()}>
                    <i className="material-icons text-primary">file_download</i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div id="scrollableDiv" style={{ overflow: "hidden" }}>

            <div className="row">
              <div className="col">
                {/* table here */}
                <Table className="table-responsive">
                  <thead className="uppercase-th">
                    <tr>
                      <th scope="col" width="3%" className="border-0"> #</th>
                      <th width="3%" scope="col" className="border-0">Image</th>
                      <th width="10%" scope="col" onClick={(e) => this.changeSorting('appName')} className={`border-0 ${this.state.sorting.sortBy === 'appName' ? this.state.sorting.sortIconClass + ' sort-icon' : 'sort-icon sort-icon-nutral'}`}>App Name</th>
                      <th width="10%" scope="col" onClick={(e) => this.changeSorting('appStore')} className={`border-0 ${this.state.sorting.sortBy === 'appStore' ? this.state.sorting.sortIconClass + ' sort-icon' : 'sort-icon sort-icon-nutral'}`}>App Store</th>
                      <th width="10%" scope="col" onClick={(e) => this.changeSorting('reportingDate')} className={`border-0 text-uppercase ${this.state.sorting.sortBy === 'reportingDate' ? this.state.sorting.sortIconClass + ' sort-icon' : 'sort-icon sort-icon-nutral'}`} >Reporting Date</th>
                      <th width="10%" scope="col" onClick={(e) => this.changeSorting('priority')} className={`border-0 ${this.state.sorting.sortBy === 'priority' ? this.state.sorting.sortIconClass + ' sort-icon' : 'sort-icon sort-icon-nutral'}`}>Priority</th>
                      <th width="10%" scope="col" onClick={(e) => this.changeSorting('status')} className={`border-0 ${this.state.sorting.sortBy === 'status' ? this.state.sorting.sortIconClass + ' sort-icon' : 'sort-icon sort-icon-nutral'}`}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.domains.map((domain, key) =>
                        <>
                          <tr
                            key={key}
                            data-toggle="collapse"
                            data-target={'.multi-collapse' + key}
                            aria-controls={'multiCollapseExample' + key}
                          >
                            <td >{key + 1}</td>
                            <td><img className="user-avatar" width="50" src={domain.imageUrl} alt="User Avatar" /></td>
                            <td><a target="_blank" rel="noreferrer" href={domain.url} title={domain.appName} className="bind-width"><p title={domain.appName} className="bind-width">{domain.appName}</p></a> </td>
                            <td><p title={domain.appStore} className="bind-width">{domain.appStore}</p></td>
                            <td>{castToDefaultDateFormat(domain.reportingDate)}</td>
                            <td><p title={domain.priority} className="bind-width">{domain.priority}</p></td>
                            <td><InfrigementStatusDropdownComponent selectedInfrigemnt={domain} selectedInfrigemntIndex={key} infrigementType="app-store" /></td>
                          </tr>
                          <tr className={'collapse multi-collapse' + key} id={'multiCollapseExample' + key}>
                            <td colSpan={9}>
                              <div className="row">
                                <div className="col-md-3">
                                  <img data-toggle="modal" width="200" data-target={'#myModal' + key} src={domain.imageUrl} className="mr-2" alt="Cinque Terre" />
                                </div>
                                <div className="col-md-9">
                                  <div className="row">

                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">App Name</span>
                                        <input type="text" value={domain.appName} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    {/* no of download */}
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Number of Download</span>
                                        <input type="text" value={domain.numberOfDownload || "N/A"} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>

                                    {/* version */}
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Version</span>
                                        <input type="text" value={domain.version || "N/A"} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Reporting Date</span>
                                        <input type="text" value={castToServerDateFormat(domain.reportingDate)} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Price</span>
                                        <input type="text" value={domain.price} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Latest Published Date</span>
                                        <input type="text" value={castToServerDateFormat(domain.latestPublishDate)} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Store Version</span>
                                        <input type="text" value={domain.storeVersion} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Reviews</span>
                                        <input type="text" value={domain.reviews} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Ratings</span>
                                        <input type="text" value={domain.ratings} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Pricing</span>
                                        <input type="text" value={domain.price} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">
                                          Reason
                                        </span>
                                        <input
                                          type="text"
                                          value={domain.reason}
                                          disabled
                                          className="form-control"
                                          placeholder=""
                                          aria-label="Username"
                                          aria-describedby="basic-addon1"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">
                                          Reason Code
                                        </span>
                                        <input
                                          type="text"
                                          value={domain.reasonCode}
                                          disabled
                                          className="form-control"
                                          placeholder=""
                                          aria-label="Username"
                                          aria-describedby="basic-addon1"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-12">
                                      <h5>Developer</h5>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Developer Name</span>
                                        <input type="text" value={domain.developerName} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Website</span>
                                        <input type="text" value={domain.website} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Address</span>
                                        <input type="text" value={domain.address} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Number Of Apps</span>
                                        <input type="text" value={domain.numberOfApps} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>


                                    {/* ----------- COMMENTS SECTION Start----------------- */}
                                    <div className="row">
                                      <div className="col-md-12">
                                        <span className="text-muted d-block mb-2">
                                          Comment
                                        </span>
                                        <div className="form-group d-flex align-items-center">
                                          <button
                                            style={{ marginRight: "10px" }}
                                            onClick={(e) =>
                                              this.updateClientCommentToDatabase(
                                                key
                                              )
                                            }
                                            className="btn btn-success"
                                          >
                                            Update comment
                                          </button>

                                          <div className="flex-grow-1">
                                            <textarea
                                              onChange={(e) =>
                                                this.updateClientCommentInArray(
                                                  e.target.value,
                                                  key
                                                )
                                              }
                                              className="form-control"
                                              value={domain?.clientComment || ""}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {/* ----------- COMMENTS SECTION END----------------- */}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <div className="modal" id={'myModal' + key}>
                            <div className="modal-dialog modal-800">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h4 className="modal-title">Image</h4>
                                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                  <img src={domain.imageUrl} width="100%" className="img-responsive" alt="Cinque Terre" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </tbody>
                </Table>
              </div>
            </div>

          </div>
        </div>

        <Footer />
      </main>
    </div>
  }

  render() {
    return <>
      {this.renderHtml()}
    </>
  }
}

const mapStateToProps = (state) => ({
  clientAppStoreFilter: state.clientAppStoreFilter,
  clientDateFilter: state.clientDateFilter
})

export default withAlert()(
  connect(mapStateToProps)(authorizeClient(requireAuth(AppStoreDashboard)))
);