import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../styles/login.css';
import { saveLoginUser, checkIs2FaVerified, verifyToken } from '../store/actions/authActions';
import { notify } from 'react-notify-toast';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPopUpMessage, setShowPopUpMessage] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']); //otp fields
  const [seconds, setSeconds] = useState(120); //timer for resend otp
  const [isActive, setIsActive] = useState(false); // Timer status
  const [isDisabledVerify, setIsDisabledVerify] = useState(true); // to show or hide for resend link
  const [isDisabledSignIn, setIsDisabledSignIn] = useState(true); // to show or hide the signin button after click
  const [isOTPValid, setIsOTPValid] = useState(true); //check otp valid
  const otpFieldsRef = useRef([]); //ref for otp fields
  const isAuthenticated = useSelector(state => state.login.isAuthenticated);
  const user = useSelector(state => state.login.user); //get user
  const is2FaVerifiedUser = useSelector(state => state.login.is2FaVerifiedUser);
  const isVerified = useSelector(state => state.login.isVerified || false);


  const response = useSelector(store => store.response)
  const dispatch = useDispatch()

  useEffect(() => {

    if (response?.status === 'ERROR') {
      notify.show(response?.payload?.data?.message, 'error');
      //if wrong otp inputs
      if (response?.payload.data?.message === 'Wrong OTP, please enter a valid OTP.') {
        setIsOTPValid(false);
      }
    }
    setIsDisabledSignIn(false);
  }, [response])

  useEffect(() => {
    if (isAuthenticated && is2FaVerifiedUser && isVerified) {
      return props.history.push('/');
    } else if (isAuthenticated && !is2FaVerifiedUser) {
      return props.history.push('/');
    } else if (isAuthenticated && is2FaVerifiedUser && !isVerified) {
      startTimer();
    }
  }, [isAuthenticated, is2FaVerifiedUser, isVerified, props.history])

  useEffect(() => {
    let intervalId;

    // If the timer is active and there are remaining seconds, update the timer
    if (isActive && seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      // When the timer reaches 0 seconds, stop it
      setIsActive(false);
    }

    // Clean up the interval when the component unmounts or when the timer is stopped
    return () => {
      clearInterval(intervalId);
    };
  }, [isActive, seconds]);

  const submitForgotPasswordForm = async (e) => {
    e.preventDefault();
    console.log(e.target['0'].value);
    window.axios.post(`users/forgotpassword`, { username: e.target['0'].value || '' })
      .then((response) => {
      })
      .catch((error) => {
        console.log('error');
      })
    setShowPopUpMessage(true);
    setTimeout(() => {
      setIsForgotPassword(false);
    }, 10000);
  }

  const submitLoginForm = async (e) => {
    setIsDisabledSignIn(true);
    e.preventDefault()
    dispatch(saveLoginUser({ email, password }))
  }

  //below function checks if the input is digit or not
  const checkDigit = (input) => {
    return /^\d+$/.test(input);
  }

  //start the timer
  const startTimer = () => {
    setSeconds(120); // Reset the timer to the initial duration
    setIsActive(true);
  };

  const handleInput = (index, value) => {
    if (value.length > 1 || !checkDigit(value)) {
      return;
    }
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);
    if (value.length === 1 && index < otpValues.length - 1) {
      otpFieldsRef.current[index + 1].focus();
    }
    const allFieldsFilled = newOtpValues.every(val => val !== '');
    setIsDisabledVerify(!allFieldsFilled);
    setIsOTPValid(true);
  };

  const handlePaste = (e) => {
    e.preventDefault(); // Prevent default paste behavior

    // Get the pasted text from the clipboard
    const pastedText = e.clipboardData.getData('text/plain');

    // Split the pasted text into individual characters
    const pastedChars = pastedText.split('').filter((digit) => checkDigit(digit));

    // Update the OTP values
    const newOtpValues = [...otpValues];
    for (let i = 0; i < otpValues.length && i < pastedChars.length; i++) {
      newOtpValues[i] = pastedChars[i];
    }
    setOtpValues(newOtpValues);
    const allFieldsFilled = pastedChars.every(val => val !== '');
    setIsDisabledVerify(!allFieldsFilled);
    setIsOTPValid(true);
  };

  const handleBackspace = (index) => {
    if (otpValues[index] !== '') {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = '';
      setOtpValues(newOtpValues);
    } else if (index > 0) {
      otpFieldsRef.current[index - 1].focus();
    }
    setIsOTPValid(true);
  };

  const submit2FaForm = async (e) => {
    e.preventDefault();
    const id = user?.id || '';
    dispatch(checkIs2FaVerified({ id, twoFactorAuthCode: otpValues }));
  }
  const resendMail = () => {
    setSeconds(120); // Reset the timer to the initial duration
    setOtpValues(['', '', '', '', '', '']);
    setIsActive(true);
    setIsOTPValid(true);
    window.axios.post(`users/v1/send2FaMail`, { id: user.id || '' })
      .then((response) => {
        console.log('Email sent');
      })
      .catch((error) => {
        console.log('error');
      })
  }


  const handleGoogleAuth = async () => {
    console.log(process.env);
    try {
      // Open the Google Auth window
      const authWindow = window.open(`${process.env.REACT_APP_API_URL}/auth/google`, '_blank', 'width=500,height=600');

      // Listen for messages from the child window
      window.addEventListener('message', (event) => {
        // Check if the message is from the expected source
        if (event.origin !== `${process.env.REACT_APP_API_URL}`) {
          return;
        }

        // Check if the message contains an error
        if (event.data && event.data.error) {
          notify.show(event.data.error || '', 'error');
          return;
        }

        // Check if the message contains token and userRole
        if (event.data && event.data.token && event.data.userRole) {
          dispatch(verifyToken(event.data.token));
        }
      }, false);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return <div className="row">
    <div className="col px-0 mx-0">
      <div className="side-background"></div>
    </div>
    <div className="col login-form-section">
      <div className="logo-section"></div>
      {is2FaVerifiedUser === true ? (
        <>
          <form autoComplete="off" onSubmit={submit2FaForm}>
            <div className='container'>
              <p>
                An OTP has been sent to your registered email {user?.maskEmailId || ''}
              </p>

              <div className="otp-container">
                {otpValues.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className={`otp-input ${isOTPValid ? '' : 'error'}`}
                    value={value}
                    onChange={(e) => handleInput(index, e.target.value)}
                    onPaste={handlePaste}
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace') {
                        handleBackspace(index);
                        setIsDisabledVerify(true);
                      }
                    }}
                    ref={(ref) => {
                      otpFieldsRef.current[index] = ref;
                    }}
                  />
                ))}
              </div>
              <Button type="submit" variant="danger" className="mt-4" disabled={isDisabledVerify} >Verify</Button>
            </div>
          </form>
          <div>
            {isActive === true ? `Resend code in 0${Math.floor(seconds / 60)}:${seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}` : <><span>Didn't receive the code?</span>
              <button className="resend-button" onClick={resendMail}>Resend code</button></>}
          </div>
        </>
      ) : isForgotPassword === false ? (
        <>
          <form autoComplete="off" className="form-signin mb-0" onSubmit={submitLoginForm}>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="floatingInput" onChange={e => setEmail(e.target.value)} placeholder="name@example.com" />
              <label htmlFor="floatingInput">Username</label>
              {response?.payload?.data?.errors?.username && <p className="text-danger">{response?.payload?.data?.errors?.username}</p>}
            </div>

            <div className="form-floating mb-3">
              <input type="password" className="form-control" id="floatingInputPassword" onChange={e => setPassword(e.target.value)} placeholder="Password" />
              <label htmlFor="floatingInputPassword">Password</label>
              {response?.payload?.data?.errors?.password && <p className="text-danger">{response?.payload?.data?.errors?.password}</p>}
            </div>
            <button className="login-btn" type="submit" disabled={isDisabledSignIn}>
              {isDisabledSignIn ? <Spinner animation="border" variant="light" size="sm" /> : 'Sign in'}
            </button>
          </form>
          <div className="fix-bottom-text">
            <button onClick={() => {
              setIsForgotPassword(!isForgotPassword);
              setShowPopUpMessage(false);
            }}
              style={{
                backgroundColor: 'white',
                color: "#F8494B",
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
            >
              Forgot Password?
            </button>
          </div>
          <div className="google-signin-container">
            <div className="google-signin-divider">
              or
            </div>
            <button className="google-signin-button" onClick={handleGoogleAuth}>
              <img src="/g-logo.png" alt="Google sign-in"/>
              <span>Sign in with Google</span>
            </button>
          </div>
          <div className="fix-bottom-text">
            <a target="_blank" rel="noreferrer" href="https://www.ldotr.red/">www.LdotR.Red</a>
          </div>
        </>
      ) :
        <div className='container'>
          <form autoComplete="off" className="form-signin mb-0" onSubmit={submitForgotPasswordForm}>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="floatingInput" onChange={e => setEmail(e.target.value)} placeholder="name@example.com" />
              <label htmlFor="floatingInput">Username</label>
              {response?.payload?.data?.errors?.username && <p className="text-danger">{response?.payload?.data?.errors?.username}</p>}
            </div>
            <div className="button-container">
              <Button variant="danger" type="submit" disabled={showPopUpMessage}>Submit</Button>
              <Button variant="danger" onClick={() => { setIsForgotPassword(!isForgotPassword) }}>Back to Sign in</Button>
            </div>
          </form>
          <div className='container'>
            {showPopUpMessage ? <p style={{ marginTop: '1%' }}>If your username is valid, a link to reset the password will be sent to the associated email address.<br />For any help, please send an email to connect@LdotR.Red</p> : ''}
          </div>
        </div>
      }
    </div>
  </div>
}

export default Login;